import "./assets/style/css/tailwind.css";
import "./assets/style/sass/main.scss";
import "./assets/style/sass/mixin.scss";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import Popper from "vue3-popper";
const AddToCalendar = require("vue-add-to-calendar");
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import YouTube from "vue3-youtube";
export default {
  install(app) {
    app.use(VueTelInput);
    app.use(VCalendar, {});
    app.use("Popper", Popper);
    app.use(AddToCalendar);
    app.component("YouTube", YouTube);
    app.use(Toast);
  },
};
