export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "searchFormation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une formation"])},
  "formationMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations"])},
  "webinarMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinaires"])},
  "clinicalCaseMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas cliniques"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "continueFormation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader de la formation médicale continue en Afrique"])},
  "learnDecorous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boostez votre carrière, avec nos certifications dans le secteur de la santé et de l’humanitaire."])},
  "doctors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins"])},
  "nurses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infirmiers"])},
  "midwives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sage-femmes"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPAGNIE"])},
  "aboutFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLUTIONS"])},
  "medicalCollaboration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration médicale"])},
  "medicalContinuousFormation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation médicale continue"])},
  "medicalMarketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing médical"])},
  "medicalLiteratureReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revue de littérature médicale"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
  "remaMedicalTechnologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REMA Medical Technologies"])},
  "userGeneralTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales d’utilisation"])},
  "confidentialPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "allRightReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "recommendedForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandés pour vous :"])},
  "Formations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations"])},
  "mostFollowedTrainings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations les plus suivies"])},
  "shortCourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations de courte durée"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heures"])},
  "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par : "])},
  "professionalTestimony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Témoignages des professionnels"])},
  "partnersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnaissance et accréditation internationales"])},
  "someMedicalConference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des conférences médicales interactives animées par des experts internationaux"])},
  "assistInLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistez en live ou en différé à des ateliers médicaux de courte durée et obtenez des attestations de succès."])},
  "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "participateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participer"])},
  "suivre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "comingWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinaires à venir"])},
  "webinarCalendarLocalHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le calendrier des webinaires est affiché dans votre heure locale"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participants"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratuit"])},
  "passedWebinars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinaires passés"])},
  "followMissedWebinars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez les webinaires que vous avez manqués"])},
  "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "connectYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte REMA"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "connectButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNEXION"])},
  "notHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas de compte ? "])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "errorLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone ou mot de passe incorrect"])},
  "errorPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un mot de passe correct (min: 6 caractères)"])},
  "errorEmailValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email non valide"])},
  "errorPasswordValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe doit contenir au moins 6 caractères"])},
  "welcomeOnRema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur REMA"])},
  "theFirstSocialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le 1er réseau professionnel dédié aux personnels de santé exerçant en Afrique"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSCRIPTION"])},
  "nameAndSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom et Nom"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer mot de passe"])},
  "beginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer"])},
  "stillHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ? "])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
  "woman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "professionalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut professionnel"])},
  "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialité"])},
  "continuousButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "importantStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Etape importante pour la validation de votre compte"])},
  "addStatusPieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une pièce justificative de votre statut professionnel"])},
  "addFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour choisir le fichier"])},
  "addPassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter votre pièce d'identité ou votre passeport"])},
  "successRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bravo ! Votre demande d'inscription a bien été envoyée. Vous recevrez bientôt un SMS ou un E-mail de confirmation pour accéder à l’application mobile REMA. \nEn attendant, connectez-vous ici pour profiter de nos programmes de formation."])},
  "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNEXION"])},
  "professionalFollowsFormation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professionnels ont suivi cette formation"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût : "])},
  "followThisFormationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre cette formation"])},
  "prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-requis "])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "targetedSkills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées"])},
  "youAlsoLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourriez aimer aussi :"])},
  "formationContain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette formation comprend :"])},
  "trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formateur(s)"])},
  "videoCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours vidéos"])},
  "audioCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cours audios"])},
  "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce(s) jointe(s)"])},
  "endCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat de fin"])},
  "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crédits"])},
  "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
  "formationCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout de l'attestation"])},
  "buyFormationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter cette formation"])},
  "confirmBuying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat de l’attestation"])},
  "byContinuous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En continuant, votre compte sera débité de "])},
  "actualSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde actuel : "])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNULER"])},
  "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIRMER"])},
  "insufficientSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde insuffisant"])},
  "notHaveMoreCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas assez de crédit, "])},
  "successPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre achat a été effectué avec succès"])},
  "beginToFollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez commencer à suivre la formation "])},
  "startTrainingButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la formation"])},
  "addForLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à ma liste pour plus tard"])},
  "freeAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès libre"])},
  "successAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attestation de succès : "])},
  "webinarSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription au webinaire"])},
  "goingToSubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de vous inscrire au webinaire. Le lien de participation vous sera envoyé par mail. Vérifiez vos spams."])},
  "participateWebinarButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participer au webinaire"])},
  "successInscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrit(e) avec succès"])},
  "inscriptionToWebinar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes inscrit(e) pour le webinaire "])},
  "availableLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", disponible via le lien suivant. Copiez-le"])},
  "presentationIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentation en "])},
  "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pages"])},
  "getAttestationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir une attestation"])},
  "myLearningHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon historique d’apprentissage"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
  "progression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "wishList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de souhaits"])},
  "myWishList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma liste de souhaits"])},
  "removeFromListButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer de ma liste"])},
  "currentModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module en cours"])},
  "insight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
  "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETOUR"])},
  "evaluationQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRE D’ÉVALUATION"])},
  "obtainAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour obtenir votre attestation, vous devez valider au moins 60% des questions "])},
  "checkCorrectAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la ou les bonne(s) réponses"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question 1"])},
  "endSuccessButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer : si succès"])},
  "endFailureButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer : si echec"])},
  "toLogout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "yourActualSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre solde actuel : "])},
  "downloadRemaApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechargez votre portefeuille de crédits en cliquant ici. Utilisez l’application mobile REMA pour obtenir gratuitement des crédits supplémentaires."])},
  "credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit(s)"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETUDIANT"])},
  "nurse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFIRMIER"])},
  "midwife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAGE-FEMME"])},
  "laboratoryAssistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LABORANTIN"])},
  "physiotherapist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KINESITHERAPEUTE"])},
  "pharmacist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHARMACIEN"])},
  "practitionerGeneralist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEDECIN GENERALISTE"])},
  "doctorInSpecialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEDECIN EN SPECIALISATION"])},
  "specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPECIALISTE"])},
  "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENSEIGNANT"])},
  "fillAllFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
  "noPassedWebinarAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun webinaire passé"])},
  "waitingAttestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes attestations en attente"])},
  "startWebinarButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer le webinaire"])},
  "seeReplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre le replay"])},
  "great": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations !"])},
  "successCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez complété avec succès cet atelier."])},
  "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé !"])},
  "failureCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas pu valider l’évaluation de cet atelier. Veuillez reprendre."])},
  "alrightButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’ACCORD"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note : "])},
  "nbrHeure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée : "])},
  "downloadAttestationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger mon attestation"])},
  "clickToDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez cliquer sur le bouton pour télécharger votre attestation"])},
  "validateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "notChangeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>NB :</b> Votre nom de profil ne pourra plus être modifié sur cette attestation, dès qu'elle est générée. Assurez-vous qu’il est exact"])},
  "webinarParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation au webinaire REMA"])},
  "addToAgenda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à votre agenda"])},
  "acceptConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’accepte les <a href='https://www.remaapp.com/TermsOfService' target='_blank' style='color: #16B853;'>Conditions Générales d'Utilisation</a> et <a href='https://www.remaapp.com/privacyPolice' target='_blank' style='color: #16B853;'>Politique de Confidentialité</a>"])},
  "mustAcceptConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez accepter les Conditions Générales d'Utilisation"])},
  "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de mot de passe"])},
  "connectToMobileApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous sur l’application mobile REMA pour réinitialiser votre mot de passe"])},
  "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
  "connectMobileAppToEditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous sur l'application mobile REMA pour accéder à votre profil"])},
  "profileEdition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de profil"])},
  "understandButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai compris"])},
  "copyLinkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COPIER LE LIEN"])},
  "rechargeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECHARGER"])},
  "emptyLearningHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre historique d’apprentissage est vide"])},
  "searchAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer votre numero de teléphone pour rechercher votre compte"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "definePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir votre mot de passe"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "notSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne sont pas identiques"])},
  "verifyConfirmCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier votre code de confirmation"])},
  "comingClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas cliniques à venir"])},
  "clinicalCaseCalendarLocalHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le calendrier des cas cliniques est affiché dans votre heure locale"])},
  "passedClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cas cliniques passés"])},
  "followMissedClinicalCases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez les cas cliniques que vous avez manqués"])},
  "participateClinicalCaseButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participer au cas clinique"])},
  "clinicalCaseSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription au cas clinique"])},
  "goingToSubscribeClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de vous inscrire au cas clinique. Le lien de participation vous sera envoyé par mail. Vérifiez vos spams."])},
  "inscriptionToClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes inscrit(e) pour le cas clinique "])},
  "clinicalCaseParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation au cas clinique REMA"])},
  "commentButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commenter"])},
  "lastComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires précédents"])},
  "numberComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" commentaire(s)"])},
  "interactiveClinicalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des cas cliniques interactifs présentés par vos confrères"])},
  "followAndDiscussInLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez et discutez en live, entre confrères, des cas de patients rares ou complexes."])},
  "clinicalCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout"])}
}