import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    uid: "",
    name: "",
    account: 0,
    history: [],
    email: "",
    countryCode: "",
  },
  getters: {
    userId: (state) => {
      return state.uid;
    },
    userName: (state) => {
      return state.name;
    },
    userAccount: (state) => {
      return state.account;
    },
    userHistory: (state) => {
      return state.history;
    },
    userEmail: (state) => {
      return state.email;
    },
    userCountryCode: (state) => {
      return state.countryCode;
    },
  },
  mutations: {
    logout(state) {
      state.uid = "";
      state.name = "";
      state.account = 0;
      state.history = [];
      state.email = "";
      state.countryCode = "";
    },
    UPDATE_USER_ID(state, user) {
      state.uid = user;
    },
    UPDATE_USER_NAME(state, uname) {
      state.name = uname;
    },
    UPDATE_USER_ACCOUNT(state, acc) {
      state.account = acc;
    },
    UPDATE_USER_HISTORY(state, his) {
      state.history = his;
    },
    UPDATE_USER_EMAIL(state, mail) {
      state.email = mail;
    },
    UPDATE_USER_COUNTRY_CODE(state, code) {
      state.countryCode = code;
    },
  },
  actions: {
    logOUT({ commit }, dec) {
      commit("logout", dec);
    },
    CREATE_USER_ID({ commit }, uid) {
      commit("UPDATE_USER_ID", uid);
    },
    CREATE_USER_NAME({ commit }, name) {
      commit("UPDATE_USER_NAME", name);
    },
    CREATE_USER_ACCOUNT({ commit }, account) {
      commit("UPDATE_USER_ACCOUNT", account);
    },
    CREATE_USER_HISTORY({ commit }, history) {
      commit("UPDATE_USER_HISTORY", history);
    },
    CREATE_USER_EMAIL({ commit }, email) {
      commit("UPDATE_USER_EMAIL", email);
    },
    CREATE_USER_COUNTRY_CODE({ commit }, code) {
      commit("UPDATE_USER_COUNTRY_CODE", code);
    },
  },
  modules: {},
});
